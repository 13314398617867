.partager {
  &__content {
    display: flex;
  }

  &__title {
    margin: 6rem 0 3rem;
    font-size: 2.5rem;
    text-transform: capitalize;
    margin-bottom: 2rem;
  }

  a {
    width: 5rem;
    height: 5rem;
    @include flexCenter;
    color: $white;
    border-radius: 50%;
    border: 3px solid $blue;
    background-color: $blue;
    font-size: 2rem;
    @include matHover;

    &:hover {
      background-color: $yellow;
      border: 3px solid $yellow;
      color: $blue;
      text-decoration: none;
    }
  }

  a:not(:last-child) {
    margin-right: 2rem;
  }
}
