@charset "UTF-8";
/*!
Theme Name: Deux Sevres Habitat
Theme URI: vernalis.fr
Author: Vernalis
Author URI: http://underscores.me/
Description: Theme Vernalis
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: vernalis-theme
*/
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~@fortawesome/fontawesome-free/css/fontawesome.css);
@import url(~@fortawesome/fontawesome-free/css/brands.css);
@import url(~@fortawesome/fontawesome-free/css/solid.css);
/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/dosis-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Dosis Regular"), local("Dosis-Regular"), url("../fonts/dosis-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/dosis-v7-latin-regular.woff2") format("woff2"), url("../fonts/dosis-v7-latin-regular.woff") format("woff"), url("../fonts/dosis-v7-latin-regular.ttf") format("truetype"), url("../fonts/dosis-v7-latin-regular.svg#Dosis") format("svg");
  /* Legacy iOS */ }

/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/dosis-v7-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Dosis Bold"), local("Dosis-Bold"), url("../fonts/dosis-v7-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/dosis-v7-latin-700.woff2") format("woff2"), url("../fonts/dosis-v7-latin-700.woff") format("woff"), url("../fonts/dosis-v7-latin-700.ttf") format("truetype"), url("../fonts/dosis-v7-latin-700.svg#Dosis") format("svg");
  /* Legacy iOS */ }

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans-v3-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Work Sans"), local("WorkSans-Regular"), url("../fonts/work-sans-v3-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/work-sans-v3-latin-regular.woff2") format("woff2"), url("../fonts/work-sans-v3-latin-regular.woff") format("woff"), url("../fonts/work-sans-v3-latin-regular.ttf") format("truetype"), url("../fonts/work-sans-v3-latin-regular.svg#WorkSans") format("svg");
  /* Legacy iOS */ }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* set to 10 for calculations*/
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility; }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 55%; } }

html,
body {
  height: 100%; }

body {
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
  font-size: 1.6rem; }

.container {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  display: block; }

a {
  color: #3897f0;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

img {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto; }
  @media only screen and (max-width: 37.5em) {
    img {
      margin: 0 auto; } }

@media only screen and (max-width: 37.5em) {
  figure {
    width: 100% !important; } }

img:not([src]) {
  visibility: hidden; }

/* Fixes Firefox anomaly during image load */
@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden; } }

.alignleft {
  float: left;
  margin-right: 1.5em; }

.alignright {
  float: right;
  margin-left: 1.5em; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.title__home {
  font-size: 4.2rem;
  text-transform: uppercase;
  color: #00a5b8;
  position: relative; }

.title__home--underline {
  display: inline-block; }
  .title__home--underline span {
    position: relative;
    z-index: 10; }
  .title__home--underline::after {
    content: '';
    width: 100%;
    position: absolute;
    background-color: #f7ea49;
    bottom: 0rem;
    z-index: 1;
    left: 0;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    height: 45%; }
  .title__home--underline:hover::after {
    height: 100%; }

.title__lien {
  font-size: 1.6rem;
  font-weight: 700; }
  .title__lien:hover {
    text-decoration: none; }

.search-form {
  margin-right: 1.5rem; }
  .search-form__input {
    border: solid 2px #00a5b8;
    background-color: #cceff1;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 5px;
    padding: 0.7rem 1rem;
    width: 21.5rem;
    font-size: 1.5rem; }
    .search-form__input::-webkit-input-placeholder {
      color: #00a5b8; }
    .search-form__input:-moz-placeholder {
      color: #00a5b8; }
    .search-form__input::-moz-placeholder {
      color: #00a5b8; }
    .search-form__input:-ms-input-placeholder {
      color: #00a5b8; }
    @media only screen and (max-width: 37.5em) {
      .search-form__input {
        width: 19rem; } }

.button__access {
  font-weight: 700;
  background-color: #00a5b8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
  .button__access:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__access a {
    color: #f7ea49;
    line-height: 1;
    padding: 0 1rem; }
  .button__access span {
    width: 4.5rem;
    background-color: #cceff1;
    display: block;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .button__access svg {
    fill: #00a5b8;
    width: 2rem;
    height: 2rem; }
  @media only screen and (max-width: 37.5em) {
    .button__access {
      position: absolute;
      height: 4rem;
      position: absolute;
      right: -3rem;
      top: 15rem;
      z-index: 99; } }

.button__yellow {
  text-transform: uppercase;
  background-color: #f7ea49;
  color: #00a5b8;
  border-radius: 5px;
  font-size: 1.8rem;
  padding: 1.5rem 1.5rem;
  line-height: 1;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  font-weight: 700; }
  .button__yellow:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__yellow:hover {
    text-decoration: none;
    color: #f7ea49;
    background-color: #00a5b8; }

.button__blue {
  background-color: #00a5b8;
  color: #f7ea49;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  line-height: 1;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  font-weight: 700; }
  .button__blue:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__blue:hover {
    text-decoration: none; }

.button__icon--span {
  display: flex;
  align-items: baseline; }
  .button__icon--span span {
    margin-left: 1rem; }
  .button__icon--span .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #f7ea49; }

.button__icon--svg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button__icon--svg a {
    color: #f7ea49;
    text-transform: uppercase;
    display: block;
    margin-right: 2rem; }
  .button__icon--svg svg {
    width: 2.5rem;
    height: 2.5rem; }

.header__bandeau {
  position: relative; }
  .header__bandeau .button__yellow {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .header__bandeau .button__yellow {
        top: 10%; } }

.bandeau {
  overflow: hidden;
  position: relative;
  height: 41.5rem; }
  .bandeau__masque {
    position: absolute;
    bottom: -3.3rem;
    width: 100%; }
    @media only screen and (min-width: 76em) {
      .bandeau__masque {
        height: 19rem;
        bottom: -5.2rem; } }
    @media only screen and (max-width: 75em) {
      .bandeau__masque {
        bottom: -4.8rem; } }
    @media only screen and (max-width: 50em) {
      .bandeau__masque {
        bottom: -6.3rem; } }
    @media only screen and (max-width: 37.5em) {
      .bandeau__masque {
        display: none; } }
  .bandeau__item, .bandeau__int {
    width: 100%;
    height: 41.5rem;
    background-position: bottom;
    background-size: cover; }

.breadcrumbs {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem; }
  .breadcrumbs .separator {
    margin: 0 1rem; }
  .breadcrumbs img {
    width: 3rem;
    height: 3rem; }
  .breadcrumbs a {
    color: #000;
    text-decoration: underline; }
  @media only screen and (max-width: 37.5em) {
    .breadcrumbs {
      font-size: 1.2rem; } }

.accordion-container {
  margin-top: 3rem;
  padding-left: 0;
  list-style-type: none;
  padding-left: 0 !important; }

.ac {
  margin-top: 1rem;
  list-style-type: none !important;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  list-style-type: none; }
  .ac::before {
    content: none; }
  .ac h3 {
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    text-transform: uppercase; }
  .ac.active > h3 {
    background-color: #00a5b8; }
  .ac > .ac-q {
    font-size: 1.5rem;
    color: #000;
    padding: 1.5rem;
    margin: 0;
    text-decoration: none;
    display: block;
    cursor: pointer;
    position: relative; }
    .ac > .ac-q::after {
      content: '+';
      text-align: center;
      font-size: 2rem;
      color: #00a5b8;
      width: 1.5rem;
      right: 3rem;
      top: 50%;
      transform: translate(0, -50%);
      position: absolute; }
  .ac > .ac-a {
    overflow: hidden;
    transition-property: all;
    transition-timing-function: ease; }
    .ac > .ac-a p {
      font-size: 1.4rem;
      line-height: 1.5;
      color: #0d1941; }
    .ac > .ac-a ul,
    .ac > .ac-a ol {
      color: #0d1941;
      font-size: 1.4rem;
      line-height: 1.5;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem; }
    .ac > .ac-a .content {
      padding: 3rem; }
  .ac.active > .ac-q::after {
    content: '-';
    color: #fff; }
  .ac.active {
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .ac.active h3 {
    color: #fff; }
  .ac .commission__membres {
    padding: 0 3rem 3rem; }
    .ac .commission__membres h3 {
      font-size: 1.6rem;
      color: #0d1941;
      margin-bottom: 1rem; }

.title__icon--grey {
  margin-bottom: 1rem; }

.documents-joints {
  background-color: #f3f3f3;
  margin-bottom: 3rem;
  padding: 2rem; }
  .documents-joints__title {
    text-transform: uppercase;
    font-size: 2.5rem; }
  .documents-joints__item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem; }
  .documents-joints__item-title {
    max-width: 70%;
    display: block;
    font-size: 1.8rem;
    color: #0d1941 !important;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase; }
    .documents-joints__item-title span {
      display: block;
      text-transform: uppercase;
      font-size: 1.4rem;
      color: #00a5b8; }
  .documents-joints__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    color: white;
    border-radius: 50%; }
    .documents-joints__icon:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    .documents-joints__icon--pdf {
      background-color: #0d1941; }
    .documents-joints__icon--xls {
      background-color: #0d1941; }
    .documents-joints__icon--doc {
      background-color: #00a5b8; }
    .documents-joints__icon--jpg {
      background-color: #cceff1; }
  .documents-joints__filesize--pdf {
    color: #0d1941; }
  .documents-joints__filesize--xls {
    color: #0d1941; }
  .documents-joints__filesize--doc {
    color: #00a5b8; }
  .documents-joints__filesize--jpg {
    color: #cceff1; }
  .documents-joints .icon-download {
    width: 2rem;
    height: 2rem; }

article .documents-joints {
  margin-top: 3rem; }
  article .documents-joints h3 {
    margin: 0;
    padding: 2.5rem 0 1.5rem;
    text-transform: uppercase; }
  article .documents-joints li::before {
    content: '' !important;
    padding: 0; }
  article .documents-joints ul {
    padding-left: 0 !important; }

.annonces {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 7rem; }
  .annonces__item {
    width: 35rem;
    border-radius: 10px;
    margin-bottom: 4rem;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    .annonces__item:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    @media only screen and (min-width: 76em) {
      .annonces__item:nth-child(3n + 2) {
        margin-left: 7.5rem;
        margin-right: 7.5rem; } }
    .annonces__item span {
      padding-left: 2rem;
      display: block; }
    @media only screen and (max-width: 75em) {
      .annonces__item {
        width: 48%; }
        .annonces__item:nth-child(even) {
          margin-left: 4%; } }
    @media only screen and (max-width: 37.5em) {
      .annonces__item {
        width: 100%;
        margin-bottom: 3rem; }
        .annonces__item:nth-child(even) {
          margin-left: 0; } }
  .annonces__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 2rem;
    padding: 2rem 0 0; }
  .annonces__image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20rem;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center; }
  .annonces__loyer {
    font-size: 4rem;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 1.5rem;
    padding-top: 1rem; }
  .annonces__agence {
    padding: 2rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center; }
    .annonces__agence svg {
      width: 2rem;
      height: 2.5rem;
      margin-right: 1rem; }
  .annonces__lien {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    font-weight: 700;
    background-color: #00a5b8;
    padding: 1.5rem 0;
    transition: all 0.2s ease; }
    .annonces__lien:hover {
      background-color: #0d1941;
      text-decoration: none; }
  @media only screen and (max-width: 37.5em) {
    .annonces {
      flex-direction: column; } }

.single-annonces__top {
  display: flex;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces__top {
      flex-direction: column; } }
  .single-annonces__top p {
    margin-bottom: 1.5rem !important; }

.single-annonces__container {
  display: flex;
  margin: 2rem 0;
  padding: 3rem 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces__container .slider-nav {
      display: none; } }

.single-annonces .ref {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 700; }
  .single-annonces .ref span {
    font-weight: 500;
    font-size: 1.4rem; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces .ref {
      font-size: 1.8rem; } }

.single-annonces .loyer {
  font-size: 4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem !important; }
  .single-annonces .loyer span {
    font-size: 1.4rem; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces .loyer {
      font-size: 2.5rem; } }

.single-annonces .surface,
.single-annonces .Type {
  font-weight: 700;
  font-size: 1.8rem; }
  .single-annonces .surface span,
  .single-annonces .Type span {
    font-weight: 500;
    display: block;
    font-size: 1.4rem; }

.single-annonces__infos {
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #0d1941;
  width: 30%; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces__infos {
      width: 35%; } }
  .single-annonces__infos p {
    line-height: 1 !important; }

.single-annonces__bottom {
  width: 100%;
  background-color: rgba(204, 239, 241, 0.3);
  padding: 2rem;
  margin-bottom: 3rem; }

.single-annonces h2 {
  margin-bottom: 1.5rem; }

.single-annonces__map {
  margin-top: 3rem; }

.single-annonces .icon-svg {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces .icon-svg {
      font-size: 1rem; } }

.single-annonces .icon-check {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem; }

.single-annonces .icon-chevron {
  margin-right: 1rem;
  width: 3rem;
  height: 3rem; }

.single-annonces .icon-infos > div {
  max-width: 80%; }

.single-annonces .infos__annonce {
  font-family: "Work Sans";
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 37.5em) {
    .single-annonces .infos__annonce {
      flex-direction: column; } }
  .single-annonces .infos__annonce > div {
    display: flex;
    align-items: center;
    width: 33%;
    margin-bottom: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .single-annonces .infos__annonce > div {
        width: 100%; } }
  .single-annonces .infos__annonce span {
    display: block; }
  .single-annonces .infos__annonce .img-dpe {
    width: 4rem;
    align-self: flex-start; }
  .single-annonces .infos__annonce .img-ges {
    width: 3rem;
    align-self: flex-start; }
  .single-annonces .infos__annonce .label {
    font-size: 1.3rem; }
  .single-annonces .infos__annonce .info {
    font-size: 1.5rem;
    font-weight: 700; }
  .single-annonces .infos__annonce .dpe {
    display: flex;
    align-items: center; }

.acf-map {
  width: 100%;
  height: 40rem;
  border: #ccc solid 1px;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .acf-map {
      height: 60rem; } }

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important; }

.slider {
  width: 65%;
  margin-right: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .slider {
      width: 60%;
      margin-right: 1.5rem; } }

.slider-annonce .slider-for__item {
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-position: center; }

.slider-annonce .slider-nav__item {
  width: 100%;
  height: 8rem;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  cursor: pointer; }

.slider-annonce .slick-current .slider-nav__item {
  opacity: 1; }

.slider-annonce .slider-container {
  padding: 1rem;
  width: 15rem;
  height: 8rem; }

.contact__title {
  color: #00a5b8;
  background-color: #cceff1;
  text-transform: uppercase;
  padding: 1rem 2rem; }

.contact__infos {
  background-color: #00a5b8;
  line-height: 1.5;
  color: #fff;
  font-family: "Work Sans";
  padding: 3rem 2rem;
  border-bottom-right-radius: 10px; }

.publications-content {
  -js-display: flex;
  display: flex; }

.titre_groupe_publications {
  margin: 3rem 0 1rem; }

.pub_item:not(:last-child) {
  margin-right: 2rem; }

.pub_item .imgpub {
  width: 18rem;
  height: 23rem;
  background-size: cover;
  background-position: center; }

.pub_item .pub_infos h4 {
  background-color: #00a5b8;
  color: #fff;
  padding: 0.5rem;
  margin-top: 1rem; }

.pub_item .pub_infos span,
.pub_item .pub_infos a {
  display: block; }

.pub_item .pub_infos span {
  font-size: 1.3rem;
  font-style: italic;
  margin-top: 0.5rem; }

.pub_item .pub_infos a {
  text-decoration: none;
  margin-top: 0.5rem;
  color: #00a5b8; }
  .pub_item .pub_infos a:hover {
    text-decoration: underline; }

.gform_wrapper form,
.page-template-annuaires form,
.page-template-emploi form,
#filter form {
  padding: 2rem;
  background-color: #f3f3f3; }
  .gform_wrapper form .gfield_label,
  .page-template-annuaires form .gfield_label,
  .page-template-emploi form .gfield_label,
  #filter form .gfield_label {
    font-weight: 400 !important;
    color: #6f6f6f; }
  .gform_wrapper form h2,
  .page-template-annuaires form h2,
  .page-template-emploi form h2,
  #filter form h2 {
    background-image: none;
    -webkit-text-fill-color: grey;
    margin-top: 3rem !important; }
  .gform_wrapper form input[type='text'],
  .gform_wrapper form input[type='email'],
  .gform_wrapper form textarea,
  .page-template-annuaires form input[type='text'],
  .page-template-annuaires form input[type='email'],
  .page-template-annuaires form textarea,
  .page-template-emploi form input[type='text'],
  .page-template-emploi form input[type='email'],
  .page-template-emploi form textarea,
  #filter form input[type='text'],
  #filter form input[type='email'],
  #filter form textarea {
    border: solid 1px #b6b6b6;
    color: #6f6f6f;
    padding: 1rem !important;
    border-radius: 2rem; }
  .gform_wrapper form input[type='submit'],
  .page-template-annuaires form input[type='submit'],
  .page-template-emploi form input[type='submit'],
  #filter form input[type='submit'] {
    border: solid 2px #00a5b8;
    padding: 1rem 2rem;
    cursor: pointer;
    background-color: transparent;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
    color: #fff;
    background-color: #00a5b8;
    text-transform: uppercase;
    border-radius: 2rem; }
    .gform_wrapper form input[type='submit']:hover,
    .page-template-annuaires form input[type='submit']:hover,
    .page-template-emploi form input[type='submit']:hover,
    #filter form input[type='submit']:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .gform_wrapper form input[type='submit']:hover,
  .page-template-annuaires form input[type='submit']:hover,
  .page-template-emploi form input[type='submit']:hover,
  #filter form input[type='submit']:hover {
    border: solid 2px #00a5b8;
    background-color: #fff;
    color: #00a5b8; }

#filter,
#filter-annuaires {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5rem;
  padding: 2rem;
  background-color: #f3f3f3; }
  #filter input[type='text'],
  #filter-annuaires input[type='text'] {
    border: solid 1px #b6b6b6;
    color: #6f6f6f;
    padding: 1rem !important;
    border-radius: 2rem; }
  #filter select,
  #filter-annuaires select {
    border: solid 1px #b6b6b6;
    color: #6f6f6f;
    padding: 1rem !important;
    border-radius: 2rem; }
  #filter input[type='submit'],
  #filter-annuaires input[type='submit'] {
    border: solid 2px #00a5b8;
    padding: 1rem 2rem;
    cursor: pointer;
    background-color: transparent;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
    color: #fff;
    background-color: #00a5b8;
    text-transform: uppercase;
    border-radius: 2rem; }
    #filter input[type='submit']:hover,
    #filter-annuaires input[type='submit']:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  #filter input[type='submit']:hover,
  #filter-annuaires input[type='submit']:hover {
    border: solid 2px #00a5b8;
    background-color: #fff;
    color: #00a5b8; }
  @media only screen and (max-width: 50em) {
    #filter,
    #filter-annuaires {
      flex-direction: column;
      justify-content: center;
      text-align: center; }
      #filter input,
      #filter input[type='text'],
      #filter select,
      #filter-annuaires input,
      #filter-annuaires input[type='text'],
      #filter-annuaires select {
        margin: 0 auto 1.5rem;
        display: block;
        width: 90%; } }

form select {
  padding: 1rem 2rem;
  background-color: #fff;
  color: #6f6f6f; }

::placeholder {
  color: #6f6f6f;
  opacity: 1; }

.gform_wrapper div.validation_error {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f57b7b; }

.gform_confirmation_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #00a5b8;
  background-color: #cceff1;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 25px;
  border-top: 2px solid #00a5b8;
  border-bottom: 2px solid #00a5b8;
  padding: 16px 0;
  clear: both;
  width: 100%;
  text-align: center; }

.logged-in .gform_wrapper div.validation_error,
.gform_confirmation_wrapper {
  top: 32px; }

@media only screen and (max-width: 75em) {
  .home .actualites__item:last-child {
    display: none; } }

div.actualites {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem; }
  div.actualites__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 3.5rem; }
    @media only screen and (max-width: 37.5em) {
      div.actualites__list {
        flex-direction: column; } }
  div.actualites__item {
    width: 38rem;
    background-color: #cceff1;
    cursor: pointer;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    div.actualites__item:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 75em) {
      div.actualites__item {
        width: 48%; } }
    @media only screen and (max-width: 37.5em) {
      div.actualites__item {
        width: 100%; }
        div.actualites__item:first-child {
          margin-bottom: 4rem; } }
  div.actualites__image {
    width: 100%;
    height: 19rem;
    background-size: cover; }
  div.actualites__text-container {
    color: #00adbb !important;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 2.5rem 2rem; }
  div.actualites__title {
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: 700; }
  div.actualites .line {
    display: block;
    width: 4rem;
    height: 4px;
    background-color: #f7ea49;
    margin-top: 1.5rem; }
  div.actualites p {
    margin-top: 1.5rem;
    color: #00adbb !important;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }
  div.actualites a {
    margin-top: 1.5rem; }
  div.actualites .button__blue {
    margin-top: 6rem; }

.actualites .main__int .container {
  flex-direction: column; }

body.actualites .actualites__list {
  flex-wrap: wrap;
  justify-content: initial; }

body.actualites .actualites__item {
  margin-bottom: 2rem; }

@media only screen and (max-width: 75em) {
  body.actualites {
    flex-direction: column;
    align-items: center; }
    body.actualites .actualites__item:nth-child(2n) {
      margin-left: 2rem; } }

@media only screen and (min-width: 76em) {
  body.actualites .actualites__item:nth-child(3n + 2) {
    margin-right: 2rem;
    margin-left: 2rem; } }

@media only screen and (max-width: 37.5em) {
  body.actualites {
    flex-direction: column;
    align-items: center; }
    body.actualites .actualites__item:nth-child(2n) {
      margin-left: 0; } }

.partager__content {
  display: flex; }

.partager__title {
  margin: 6rem 0 3rem;
  font-size: 2.5rem;
  text-transform: capitalize;
  margin-bottom: 2rem; }

.partager a {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #00a5b8;
  background-color: #00a5b8;
  font-size: 2rem;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
  .partager a:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .partager a:hover {
    background-color: #f7ea49;
    border: 3px solid #f7ea49;
    color: #00a5b8;
    text-decoration: none; }

.partager a:not(:last-child) {
  margin-right: 2rem; }

.header__menu {
  display: flex;
  margin-left: auto;
  width: 70rem;
  justify-content: space-between; }
  .header__menu > li {
    position: relative; }
    .header__menu > li > a {
      text-transform: uppercase;
      color: #0d1941;
      font-weight: 700;
      padding: 1.5rem 0;
      display: block;
      text-align: center;
      position: relative; }
    .header__menu > li:not(:first-child) > a::after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background-color: #00a5b8;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .header__menu > li:hover .sub-menu {
      display: block; }
    .header__menu > li:not(:first-child) {
      width: 33%; }
    .header__menu > li:first-child {
      padding-right: 4rem; }
  .header__menu .sub-menu {
    z-index: 99;
    display: none;
    background-color: rgba(0, 165, 184, 0.9);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 29rem;
    padding: 1rem 0;
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.2), 0 11px 7px 0 rgba(0, 0, 0, 0.2); }
    .header__menu .sub-menu::before {
      content: '';
      border: 1px solid #000;
      position: absolute;
      z-index: 99;
      display: block;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 10px 12.5px;
      border-color: transparent transparent #00afbb transparent; }
    .header__menu .sub-menu a {
      color: #fff;
      display: block;
      padding: 0.5rem 2rem; }
    .header__menu .sub-menu a:hover {
      font-weight: 700;
      border-left: 5px solid #f7ea49;
      text-decoration: none; }
  @media only screen and (max-width: 75em) {
    .header__menu {
      display: none; } }

#menu-lvl3 {
  background-color: #cceff1;
  padding: 3.5rem 0;
  border-bottom-right-radius: 2rem;
  margin-bottom: 3rem; }
  #menu-lvl3 h2 {
    font-size: 1.8rem;
    margin-left: 2rem;
    position: relative;
    margin-bottom: 2rem; }
    #menu-lvl3 h2 a {
      color: #00a5b8; }
  #menu-lvl3 .submenu > li:not(:last-child) {
    margin-bottom: 2rem; }
  #menu-lvl3 .submenu a {
    display: block;
    padding-left: 2rem;
    color: #000; }
  #menu-lvl3 .submenu .current_page_item {
    border-left: solid 4px #000; }
  #menu-lvl3 .submenu a:hover,
  #menu-lvl3 .submenu .current_page_item > a,
  #menu-lvl3 .submenu .current_sub_sub_item a {
    color: #00a5b8;
    font-weight: 700;
    text-decoration: underline; }
  #menu-lvl3 .subsubmenu {
    padding-left: 2rem; }
    #menu-lvl3 .subsubmenu > li {
      margin-bottom: 1rem; }
    #menu-lvl3 .subsubmenu li:first-child {
      margin-top: 1rem; }

.pushbar__icon {
  display: none; }
  @media only screen and (max-width: 75em) {
    .pushbar__icon {
      margin: 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .pushbar__icon--close, .pushbar__icon--open {
    font-size: 2.5rem;
    color: #00a5b8; }
  .pushbar__icon--close {
    margin: 0.5rem 2rem; }

.title__icon {
  text-transform: uppercase;
  font-family: "Work Sans";
  font-size: 1.1rem;
  font-weight: 600;
  color: #00a5b8; }

.pushbar.opened {
  display: block; }

html.pushbar_locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none; }

.pushbar_locked .pushbar_main_content.pushbar_blur {
  filter: blur(15px); }

.pushbar {
  z-index: 1000;
  position: fixed;
  will-change: transform;
  overflow-y: auto;
  transition: transform 0.5s ease;
  will-change: transform;
  background: #cceff1;
  border-left: solid 3px #f7ea49;
  display: none; }
  @media only screen and (max-width: 37.5em) {
    .pushbar {
      display: block; } }

.pushbar_overlay {
  z-index: -999;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  will-change: opacity;
  transition: opacity 0.5s ease;
  opacity: 0;
  will-change: opacity;
  background: #3c3442; }

html.pushbar_locked .pushbar_overlay {
  opacity: 0.8;
  z-index: 999;
  transition: opacity 0.5s ease; }

.pushbar.from_left {
  top: 0;
  left: 0;
  width: 256px;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  transform: translateZ(0) translateX(-100%); }

.pushbar.from_right {
  top: 0;
  right: 0;
  width: 256px;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  transform: translateZ(0) translateX(100%); }

.pushbar.from_top {
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  transform: translateZ(0) translateY(-100%); }

.pushbar.from_bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  transform: translateZ(0) translateY(100%); }

.pushbar.opened {
  transform: translateX(0px) translateY(0px); }

.pushbar__menu {
  padding: 0 1rem; }
  .pushbar__menu ul {
    padding: 0 1.5rem; }
  .pushbar__menu > li:first-child {
    display: none; }
  .pushbar__menu a {
    color: #0d1941; }
  .pushbar__menu .sub-menu .active a {
    color: #00a5b8;
    text-decoration: underline; }
  .pushbar__menu > li > a {
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 1rem 0;
    display: block;
    background-color: #00a5b8;
    color: #f7ea49;
    font-weight: 700;
    text-align: center; }
  .pushbar__menu li {
    padding: 1rem 0; }

.header {
  border-top: solid 5px #f7ea49; }
  .header__top .container {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    padding: 1.5rem 0; }
    @media only screen and (max-width: 75em) {
      .header__top .container {
        padding: 1.5rem 2rem;
        justify-content: center; } }
  @media only screen and (max-width: 75em) {
    .header__top {
      display: flex;
      align-items: center; } }
  .header__bottom {
    border-top: solid 2px #f7ea49;
    background-color: #cceff1; }
    .header__bottom .container {
      position: relative; }
  .header__logo {
    position: absolute;
    left: 0;
    top: -6.7rem;
    z-index: 99; }
    @media only screen and (max-width: 75em) {
      .header__logo {
        width: 18rem; } }
    @media only screen and (max-width: 50em) {
      .header__logo {
        width: 18rem;
        top: -0.2rem; } }

.main__int {
  padding-top: 5rem; }
  @media only screen and (max-width: 75em) {
    .main__int {
      flex-direction: column;
      padding: 5rem 2rem 0; } }
  .main__int .container {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 75em) {
      .main__int .container {
        flex-direction: column; } }
  .main__int .page-int__top {
    margin-bottom: 5rem; }
  .main__int .page-int__left > div {
    width: 28rem; }
  @media only screen and (max-width: 75em) {
    .main__int .page-int__left {
      order: 2;
      margin-top: 3rem;
      max-width: 100%; } }
  .main__int article {
    width: 100%;
    max-width: 88rem; }
    .main__int article h1 {
      margin-bottom: 4rem; }
      @media only screen and (max-width: 37.5em) {
        .main__int article h1 {
          font-size: 3rem; } }
    .main__int article h2 {
      color: #00a5b8;
      margin-bottom: 1.5rem; }
    .main__int article h3 {
      font-size: 1.7rem;
      margin-bottom: 1.5rem; }
    .main__int article p a {
      color: #00a5b8; }
    .main__int article p,
    .main__int article ul,
    .main__int article ol,
    .main__int article figcaption {
      font-family: "Work Sans";
      margin-bottom: 2rem;
      color: #0d1941;
      line-height: 1.5; }
    .main__int article figcaption {
      font-size: 1.3rem;
      text-align: right;
      font-style: italic; }
    .main__int article ul,
    .main__int article ol {
      padding-left: 2rem;
      list-style: none; }
    .main__int article ul li:before {
      /* Unicode bullet symbol */
      content: '\2022 ';
      /* Bullet color */
      color: #00a5b8;
      padding-right: 0.5em; }
    .main__int article ul li a {
      color: #00a5b8; }
    .main__int article ol {
      counter-reset: li; }
      .main__int article ol li {
        counter-increment: li; }
      .main__int article ol li::before {
        content: counter(li);
        color: #00a5b8;
        padding-right: 0.5em; }
    .main__int article table {
      width: 100%;
      font-weight: 400;
      border-collapse: separate;
      border-spacing: 0 2px;
      margin-bottom: 2rem; }
      .main__int article table tr:nth-child(odd) {
        background-color: #cceff1; }
      .main__int article table td {
        padding: 1rem;
        text-align: left; }
      .main__int article table tbody tr:first-child {
        background-color: #00a5b8;
        color: #0d1941;
        font-size: 1.6em;
        font-weight: 700; }

.page-template-locataire article,
.actualites article,
.page.publications article {
  flex-grow: 1;
  max-width: 100%; }

.annonces-template-default article,
.single-post article,
.contact article {
  width: 82rem; }
  @media only screen and (max-width: 37.5em) {
    .annonces-template-default article,
    .single-post article,
    .contact article {
      width: 100%; } }

.annonces-template-default .page-int__right,
.single-post .page-int__right,
.contact .page-int__right {
  width: 35rem;
  margin-top: 7.6rem; }
  @media only screen and (max-width: 37.5em) {
    .annonces-template-default .page-int__right,
    .single-post .page-int__right,
    .contact .page-int__right {
      width: 100%; } }
  .annonces-template-default .page-int__right .gform_wrapper .top_label input.medium,
  .annonces-template-default .page-int__right .gform_wrapper .top_label select.medium,
  .single-post .page-int__right .gform_wrapper .top_label input.medium,
  .single-post .page-int__right .gform_wrapper .top_label select.medium,
  .contact .page-int__right .gform_wrapper .top_label input.medium,
  .contact .page-int__right .gform_wrapper .top_label select.medium {
    width: 100%; }
  .annonces-template-default .page-int__right .gform_wrapper ul.gform_fields li.gfield,
  .single-post .page-int__right .gform_wrapper ul.gform_fields li.gfield,
  .contact .page-int__right .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0; }

.load-bar.invisible {
  display: none; }

.load-bar {
  position: relative;
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 3px;
  background-color: #cceff1; }

.bar {
  content: '';
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center; }

.bar:nth-child(1) {
  background-color: #00a5b8;
  animation: loading 2.5s linear infinite; }

.bar:nth-child(2) {
  background-color: #f7ea49;
  animation: loading 2.5s linear 1s infinite; }

.bar:nth-child(3) {
  background-color: #cceff1;
  animation: loading 2.5s linear 2s infinite; }

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100; }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10; }
  to {
    left: 0;
    width: 100%; } }

.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  border-top: 0;
  background: #fff;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box; }

.autocomplete-suggestion {
  position: relative;
  padding: 1rem;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.02em;
  color: #333; }

.autocomplete-suggestion b {
  font-weight: bold;
  color: #00a5b8; }

.autocomplete-suggestion.selected {
  background: #f0f0f0; }

.footer {
  background-color: #0d1941;
  margin-top: 5rem;
  padding: 5rem 0; }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 50em) {
      .footer .container {
        flex-direction: column;
        align-items: center; } }
  .footer__adresse {
    display: flex;
    justify-content: space-between;
    max-width: 47rem;
    width: 100%; }
    .footer__adresse h2 {
      text-transform: uppercase;
      color: #f7ea49;
      font-size: 2rem; }
    .footer__adresse p {
      color: #fff; }
    @media only screen and (max-width: 37.5em) {
      .footer__adresse {
        flex-direction: column;
        align-items: center; }
        .footer__adresse img {
          margin-bottom: 3rem; } }
  .footer__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 24.5rem;
    height: 16rem;
    width: 100%; }
    @media only screen and (max-width: 50em) {
      .footer__buttons {
        padding: 4rem 0;
        height: 25rem; } }
  .footer__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 18rem;
    height: 16rem;
    width: 100%; }
    @media only screen and (max-width: 37.5em) {
      .footer__logos {
        max-width: 25rem;
        font-size: 1.7rem; }
        .footer__logos p {
          margin-bottom: 2rem; } }
    .footer__logos p {
      color: #f7ea49; }
    .footer__logos .images {
      display: flex;
      justify-content: space-between; }
      .footer__logos .images img {
        height: 6rem;
        align-self: flex-start; }
  .footer__bottom {
    padding: 1.5rem 0;
    background-color: #cceff1; }
    .footer__bottom ul,
    .footer__bottom .container {
      justify-content: center;
      display: flex; }
      @media only screen and (max-width: 37.5em) {
        .footer__bottom ul,
        .footer__bottom .container {
          flex-direction: column;
          align-items: center; } }
    .footer__bottom li:not(:last-child) {
      margin-right: 2rem; }
      @media only screen and (max-width: 37.5em) {
        .footer__bottom li:not(:last-child) {
          margin-right: 0;
          margin-bottom: 1.5rem; } }
    .footer__bottom a {
      color: #00a5b8;
      font-family: "Work Sans"; }

@media print {
  body {
    /* Modifications : la couleur de fond de page - la police - l'unité utilisée pour la taille de la police  */
    background-color: #fff;
    font-family: Serif;
    font-size: 15pt; }
  header,
  footer,
  .page-int__left,
  .partager,
  #chemin {
    /* Les éléments qui ne seront pas affichés  */
    display: none; }
  article {
    width: 100%;
    max-width: 100%; }
  h1 {
    /* Affichage du titre */
    margin: 0;
    padding: 0;
    text-indent: 0;
    line-height: 25pt;
    font-size: 25pt; }
  h2,
  h3,
  a {
    /* Modification de la couleur des titres et liens */
    color: #000; } }
