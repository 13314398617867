.breadcrumbs {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;

  .separator {
    margin: 0 1rem;
  }

  img {
    width: 3rem;
    height: 3rem;
  }

  a {
    color: $black;
    text-decoration: underline;
  }

  @include respond(phone) {
    font-size: 1.2rem;
  }
}
