.contact__title {
  color: $blue;
  background-color: $light-blue;
  text-transform: uppercase;
  padding: 1rem 2rem;
}

.contact__infos {
  background-color: $blue;
  line-height: 1.5;
  color: $white;
  font-family: $text;
  padding: 3rem 2rem;
  border-bottom-right-radius: 10px;
}
