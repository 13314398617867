.publications-content {
	-js-display: flex;
	display: flex;
}

.titre_groupe_publications {
	margin: 3rem 0 1rem;
}

.pub_item {
	&:not(:last-child) {
		margin-right: 2rem;
	}

	.imgpub {
		width: 18rem;
		height: 23rem;
		background-size: cover;
		background-position: center;
	}

	.pub_infos {
		h4 {
			background-color: $blue;
			color: #fff;
			padding: 0.5rem;
			margin-top: 1rem;
		}
		span,
		a {
			display: block;
		}

		span {
			font-size: 1.3rem;
			font-style: italic;
			margin-top: 0.5rem;
		}

		a {
			text-decoration: none;
			margin-top: 0.5rem;
			color: $blue;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
