.accordion-container {
	margin-top: 3rem;
	padding-left: 0;
	list-style-type: none;
	padding-left: 0 !important;
}

.ac {
	margin-top: 1rem;
	list-style-type: none !important;
	background-color: $grey;
	box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
	transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
	list-style-type: none;

	&::before {
		content: none;
	}

	h3 {
		transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
		text-transform: uppercase;
	}

	&.active > h3 {
		background-color: $blue;
	}
	> {
		.ac-q {
			font-size: 1.5rem;
			color: $black;
			padding: 1.5rem;
			margin: 0;
			text-decoration: none;
			display: block;
			cursor: pointer;
			position: relative;
			&::after {
				content: '+';
				text-align: center;
				font-size: 2rem;
				color: $blue;
				width: 1.5rem;
				right: 3rem;
				top: 50%;
				transform: translate(0, -50%);
				position: absolute;
			}
		}
		.ac-a {
			overflow: hidden;
			transition-property: all;
			transition-timing-function: ease;
			p {
				font-size: 1.4rem;
				line-height: 1.5;
				color: $dark-blue;
				//margin: 0;
			}

			ul,
			ol {
				color: $dark-blue;
				font-size: 1.4rem;
				line-height: 1.5;
				margin-left: 1.5rem;
				margin-bottom: 1.5rem;
			}

			.content {
				padding: 3rem;
			}
		}
	}
	&.active > .ac-q::after {
		content: '-';
		color: $white;
	}

	&.active {
		box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1),
			0 11px 7px 0 rgba(0, 0, 0, 0.1);
	}

	&.active h3 {
		color: $white;
	}

	.commission__membres {
		padding: 0 3rem 3rem;
		h3 {
			font-size: 1.6rem;
			color: $dark-blue;
			margin-bottom: 1rem;
		}
	}
}
