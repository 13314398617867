/*!
Theme Name: Deux Sevres Habitat
Theme URI: vernalis.fr
Author: Vernalis
Author URI: http://underscores.me/
Description: Theme Vernalis
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: vernalis-theme
*/

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';

@import '/abstract/variables';
@import '/abstract/mixins';

@import '/base/fonts';
@import '/base/base';
@import '/base/headings';

@import '/components/search-form';
@import '/components/buttons';
@import '/components/bandeaux';
@import '/components/int/breadcrumbs';
@import '/components/int/accordions';
@import '/components/int/liste-docs';
@import '/components/int/annonces-int';
@import '/components/int/contact';
@import '/components/int/publications';
@import '/components/forms';
@import '/components/actualites';
@import '/components/share';

@import '/menus/header-menu';
@import '/menus/int-menu';
@import '/menus/pushbar';

// @import '/sliders/slider-actualites';

@import '/layout/header';
@import '/layout/pages';
@import '/layout/footer';
@import '/layout/print';
