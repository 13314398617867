.annonces {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 7rem;

	&__item {
		width: 35rem;
		border-radius: 10px;
		margin-bottom: 4rem;
		@include matHover;

		@include respond(desktop) {
			&:nth-child(3n + 2) {
				margin-left: 7.5rem;
				margin-right: 7.5rem;
			}
		}

		span {
			padding-left: 2rem;
			display: block;
		}

		@include respond(tab-land) {
			width: 48%;
			&:nth-child(even) {
				margin-left: 4%;
			}
		}

		@include respond(phone) {
			width: 100%;
			margin-bottom: 3rem;

			&:nth-child(even) {
				margin-left: 0;
			}
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-transform: uppercase;
		font-size: 2rem;
		padding: 2rem 0 0;
	}

	&__image {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 20rem;
		width: 100%;
		position: relative;
		background-size: cover;
		background-position: center;
	}

	&__loyer {
		font-size: 4rem;
		border-bottom: 1px solid rgb(214, 214, 214);
		padding-bottom: 1.5rem;
		padding-top: 1rem;
	}

	&__agence {
		padding: 2rem;
		font-size: 1.8rem;
		display: flex;
		align-items: center;
		svg {
			width: 2rem;
			height: 2.5rem;
			margin-right: 1rem;
		}
	}

	&__lien {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		@include flexCenter;
		width: 100%;
		color: $white;
		font-weight: 700;
		background-color: $blue;
		padding: 1.5rem 0;
		transition: all 0.2s ease;

		&:hover {
			background-color: $dark-blue;
			text-decoration: none;
		}
	}

	@include respond(phone) {
		flex-direction: column;
	}
}

.single-annonces {
	&__top {
		display: flex;
		margin-bottom: 3rem;

		@include respond(phone) {
			flex-direction: column;
		}

		p {
			margin-bottom: 1.5rem !important;
		}
	}

	&__container {
		display: flex;
		margin: 2rem 0;
		padding: 3rem 0;
		border-top: 1px solid rgb(235, 235, 235);
		border-bottom: 1px solid rgb(235, 235, 235);

		@include respond(phone) {
			.slider-nav {
				display: none;
			}
		}
	}

	.ref {
		display: flex;
		flex-direction: column;
		font-size: 2rem;
		font-weight: 700;
		span {
			font-weight: 500;
			font-size: 1.4rem;
		}

		@include respond(phone) {
			font-size: 1.8rem;
		}
	}

	.loyer {
		font-size: 4rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 2.5rem !important;

		span {
			font-size: 1.4rem;
		}

		@include respond(phone) {
			font-size: 2.5rem;
		}
	}

	.surface,
	.Type {
		font-weight: 700;
		font-size: 1.8rem;
		span {
			font-weight: 500;
			display: block;
			font-size: 1.4rem;
		}
	}

	&__infos {
		font-family: $base;
		color: $dark-blue;
		width: 30%;

		@include respond(phone) {
			width: 35%;
		}

		p {
			line-height: 1 !important;
		}
	}

	&__bottom {
		width: 100%;
		background-color: rgba($color: $light-blue, $alpha: 0.3);
		padding: 2rem;
		margin-bottom: 3rem;
	}

	h2 {
		margin-bottom: 1.5rem;
	}

	&__map {
		margin-top: 3rem;
	}

	.icon-svg {
		display: flex;
		align-items: center;

		@include respond(phone) {
			font-size: 1rem;
		}
	}

	.icon-check {
		margin-right: 1rem;
		width: 2rem;
		height: 2rem;
	}

	.icon-chevron {
		margin-right: 1rem;
		width: 3rem;
		height: 3rem;
	}

	.icon-infos > div {
		max-width: 80%;
	}

	.infos__annonce {
		font-family: $text;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@include respond(phone) {
			flex-direction: column;
		}
		& > div {
			display: flex;
			align-items: center;
			width: 33%;
			margin-bottom: 2rem;

			@include respond(phone) {
				width: 100%;
			}
		}

		span {
			display: block;
		}

		.img-dpe {
			width: 4rem;
			align-self: flex-start;
		}
		.img-ges {
			width: 3rem;
			align-self: flex-start;
		}

		.label {
			font-size: 1.3rem;
		}

		.info {
			font-size: 1.5rem;
			font-weight: 700;
		}

		.dpe {
			display: flex;
			align-items: center;
		}
	}
}

.acf-map {
	width: 100%;
	height: 40rem;
	border: #ccc solid 1px;
	margin-bottom: 3rem;

	@include respond(phone) {
		height: 60rem;
	}
}

/* fixes potential theme css conflict */
.acf-map img {
	max-width: inherit !important;
}

.slider {
	width: 65%;
	margin-right: 3rem;

	@include respond(phone) {
		width: 60%;
		margin-right: 1.5rem;
	}
}
.slider-annonce {
	.slider-for__item {
		width: 100%;
		height: 30rem;
		background-size: cover;
		background-position: center;
	}
	.slider-nav__item {
		width: 100%;
		height: 8rem;
		background-size: cover;
		background-position: center;
		opacity: 0.5;
		cursor: pointer;
	}
	.slick-current .slider-nav__item {
		opacity: 1;
	}

	.slider-container {
		padding: 1rem;
		width: 15rem;
		height: 8rem;
	}
}
