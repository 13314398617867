.gform_wrapper,
.page-template-annuaires,
.page-template-emploi,
#filter {
	form {
		padding: 2rem;
		background-color: #f3f3f3;
		.gfield_label {
			font-weight: 400 !important;
			color: #6f6f6f;
		}
		h2 {
			background-image: none;
			-webkit-text-fill-color: grey;
			margin-top: 3rem !important;
		}

		input[type='text'],
		input[type='email'],
		textarea {
			border: solid 1px #b6b6b6;
			color: #6f6f6f;
			padding: 1rem !important;
			border-radius: 2rem;
		}

		input[type='submit'] {
			border: solid 2px $blue;
			padding: 1rem 2rem;
			cursor: pointer;
			background-color: transparent;
			@include matHover;
			color: $white;
			background-color: $blue;
			text-transform: uppercase;
			border-radius: 2rem;
		}

		input[type='submit']:hover {
			border: solid 2px $blue;
			background-color: $white;
			color: $blue;
		}
	}
}

#filter,
#filter-annuaires {
	display: flex;
	align-items: flex-start;
	//flex-direction: column;
	justify-content: space-between;
	margin-bottom: 5rem;
	padding: 2rem;
	background-color: #f3f3f3;

	input[type='text'] {
		border: solid 1px #b6b6b6;
		color: #6f6f6f;
		padding: 1rem !important;
		border-radius: 2rem;
	}
	select {
		border: solid 1px #b6b6b6;
		color: #6f6f6f;
		padding: 1rem !important;

		border-radius: 2rem;
	}

	input[type='submit'] {
		border: solid 2px $blue;
		padding: 1rem 2rem;
		cursor: pointer;
		background-color: transparent;
		@include matHover;
		color: $white;
		background-color: $blue;
		text-transform: uppercase;
		border-radius: 2rem;
	}

	input[type='submit']:hover {
		border: solid 2px $blue;
		background-color: $white;
		color: $blue;
	}

	@include respond(tab-port) {
		flex-direction: column;
		justify-content: center;
		text-align: center;

		input,
		input[type='text'],
		select {
			margin: 0 auto 1.5rem;
			display: block;
			width: 90%;
		}
	}
}

form select {
	padding: 1rem 2rem;
	background-color: $white;
	color: #6f6f6f;
}

::placeholder {
	color: #6f6f6f;
	opacity: 1;
}

.gform_wrapper div.validation_error {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #f57b7b;
}
.gform_confirmation_wrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	color: $blue;
	background-color: $light-blue;
	font-size: 1em;
	font-weight: 700;
	margin-bottom: 25px;
	border-top: 2px solid $blue;
	border-bottom: 2px solid $blue;
	padding: 16px 0;
	clear: both;
	width: 100%;
	text-align: center;
}
.logged-in .gform_wrapper div.validation_error,
.gform_confirmation_wrapper {
	top: 32px;
}
