.title__icon--grey {
  margin-bottom: 1rem;
}
.documents-joints {
  background-color: $grey;
  margin-bottom: 3rem;
  padding: 2rem;

  // a {
  //   color: $black !important;
  // }

  &__title {
    text-transform: uppercase;
    font-size: 2.5rem;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__item-title {
    max-width: 70%;
    display: block;
    font-size: 1.8rem;
    color: $dark-blue !important;
    font-family: $base;
    text-transform: uppercase;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 1.4rem;
      color: $blue;
    }
  }

  &__icon {
    @include flexCenter;
    @include matHover;
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    color: white;
    border-radius: 50%;

    &--pdf {
      background-color: $pdf;
    }
    &--xls {
      background-color: $xls;
    }
    &--doc {
      background-color: $doc;
    }
    &--jpg {
      background-color: $jpg;
    }
  }

  &__filesize--pdf {
    color: $pdf;
  }

  &__filesize--xls {
    color: $xls;
  }

  &__filesize--doc {
    color: $doc;
  }

  &__filesize--jpg {
    color: $jpg;
  }

  .icon-download {
    width: 2rem;
    height: 2rem;
  }
}

article .documents-joints {
  margin-top: 3rem;
  //padding-top: 0;

  h3 {
    margin: 0;
    padding: 2.5rem 0 1.5rem;
    text-transform: uppercase;
  }

  li::before {
    content: '' !important;
    padding: 0;
  }

  ul {
    padding-left: 0 !important;
  }
}
