.main__int {
	padding-top: 5rem;

	@include respond(tab-land) {
		flex-direction: column;
		padding: 5rem 2rem 0;
	}

	.container {
		display: flex;
		justify-content: space-between;

		@include respond(tab-land) {
			flex-direction: column;
		}
		@include respond(tab-land) {
			//padding: 0 2rem !important;
		}
	}

	.page-int__top {
		margin-bottom: 5rem;
	}

	.page-int__left {
		& > div {
			width: 28rem;
		}

		@include respond(tab-land) {
			order: 2;
			margin-top: 3rem;
			max-width: 100%;
		}
	}

	article {
		width: 100%;
		max-width: 88rem;

		h1 {
			margin-bottom: 4rem;

			@include respond(phone) {
				font-size: 3rem;
			}
		}

		h2 {
			color: $blue;
			margin-bottom: 1.5rem;
		}

		h3 {
			font-size: 1.7rem;
			margin-bottom: 1.5rem;
		}

		p a {
			color: $blue;
		}

		p,
		ul,
		ol,
		figcaption {
			font-family: $text;
			margin-bottom: 2rem;
			color: $dark-blue;
			line-height: 1.5;
		}

		figcaption {
			font-size: 1.3rem;
			text-align: right;
			font-style: italic;
		}

		ul,
		ol {
			padding-left: 2rem;
			list-style: none;
		}

		ul li {
			&:before {
				/* Unicode bullet symbol */
				content: '\2022 ';
				/* Bullet color */
				color: $blue;
				padding-right: 0.5em;
			}

			a {
				color: $blue;
			}
		}

		ol {
			counter-reset: li;
			li {
				counter-increment: li;
			}
			li::before {
				content: counter(li);
				color: $blue;
				padding-right: 0.5em;
			}
		}

		table {
			width: 100%;
			font-weight: 400;
			border-collapse: separate;
			border-spacing: 0 2px;
			margin-bottom: 2rem;

			tr:nth-child(odd) {
				background-color: $light-blue;
			}

			td {
				padding: 1rem;
				text-align: left;
			}

			tbody tr:first-child {
				background-color: $blue;
				color: $dark-blue;
				font-size: 1.6em;
				font-weight: 700;
			}
		}
	}
}

.page-template-locataire,
.actualites,
.page.publications {
	article {
		flex-grow: 1;
		max-width: 100%;
	}
}

.annonces-template-default,
.single-post,
.contact {
	article {
		width: 82rem;

		@include respond(phone) {
			width: 100%;
		}
	}
	.page-int__right {
		width: 35rem;
		margin-top: 7.6rem;

		@include respond(phone) {
			width: 100%;
		}

		.gform_wrapper .top_label input.medium,
		.gform_wrapper .top_label select.medium {
			width: 100%;
		}

		.gform_wrapper ul.gform_fields li.gfield {
			padding-right: 0;
		}
	}
}

.load-bar.invisible {
	display: none;
}

.load-bar {
	position: relative;
	display: block;
	margin-top: 20px;
	width: 100%;
	height: 3px;
	background-color: $light-blue;
}
.bar {
	content: '';
	display: inline;
	position: absolute;
	width: 0;
	height: 100%;
	left: 50%;
	text-align: center;
}
.bar:nth-child(1) {
	background-color: $blue;
	animation: loading 2.5s linear infinite;
}
.bar:nth-child(2) {
	background-color: $yellow;
	animation: loading 2.5s linear 1s infinite;
}
.bar:nth-child(3) {
	background-color: $light-blue;
	animation: loading 2.5s linear 2s infinite;
}
@keyframes loading {
	from {
		left: 50%;
		width: 0;
		z-index: 100;
	}
	33.3333% {
		left: 0;
		width: 100%;
		z-index: 10;
	}
	to {
		left: 0;
		width: 100%;
	}
}

.autocomplete-suggestions {
	text-align: left;
	cursor: default;
	border: 1px solid #ccc;
	border-top: 0;
	background: #fff;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);

	/* core styles should not be changed */
	position: absolute;
	display: none;
	z-index: 9999;
	max-height: 254px;
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;
}
.autocomplete-suggestion {
	position: relative;
	padding: 1rem;
	line-height: 23px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.02em;
	color: #333;
}
.autocomplete-suggestion b {
	font-weight: bold;
	color: $blue;
}
.autocomplete-suggestion.selected {
	background: #f0f0f0;
}
