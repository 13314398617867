#menu-lvl3 {
	background-color: $light-blue;
	padding: 3.5rem 0;
	border-bottom-right-radius: 2rem;
	margin-bottom: 3rem;

	h2 {
		font-size: 1.8rem;
		margin-left: 2rem;
		position: relative;
		margin-bottom: 2rem;
		a {
			color: $blue;
		}
	}

	.submenu {
		> li:not(:last-child) {
			margin-bottom: 2rem;
		}
		a {
			display: block;
			padding-left: 2rem;
			color: $black;
		}

		.current_page_item {
			border-left: solid 4px $black;
		}

		a:hover,
		.current_page_item > a,
		.current_sub_sub_item a {
			color: $blue;
			font-weight: 700;
			text-decoration: underline;
		}
	}

	.subsubmenu {
		padding-left: 2rem;

		> li {
			margin-bottom: 1rem;
		}

		li:first-child {
			margin-top: 1rem;
		}
	}
}
