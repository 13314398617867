@media print {
  body {
    /* Modifications : la couleur de fond de page - la police - l'unité utilisée pour la taille de la police  */
    background-color: #fff;
    font-family: Serif;
    font-size: 15pt;
  }

  header,
  footer,
  .page-int__left,
  .partager,
  #chemin {
    /* Les éléments qui ne seront pas affichés  */
    display: none;
  }

  article {
    width: 100%;
    max-width: 100%;
  }

  h1 {
    /* Affichage du titre */
    margin: 0;
    padding: 0;
    text-indent: 0;
    line-height: 25pt;
    font-size: 25pt;
  }

  h2,
  h3,
  a {
    /* Modification de la couleur des titres et liens */
    color: #000;
  }
}
